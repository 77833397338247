import { Anchor, AnchorProps, useMantineTheme } from '@mantine/core';
import { useGaEvents } from 'hooks/useSendButtonClickedEvent';
import { ReactNode, useMemo } from 'react';
import { IoOpenOutline } from 'react-icons/io5';
import { useOffsiteModal } from 'recoil/offsiteModal';
import { isTrustedUrl } from 'utils/url';
import classes from './OffsiteLink.module.css';

interface Props extends AnchorProps {
  label?: string;
  link: string;
  withConfirm?: boolean;
  withIcon?: boolean;
  children?: ReactNode;
  linkColor?: string;
}

export const OffsiteLink = ({
  label,
  link,
  size = 'sm',
  withIcon = true,
  linkColor,
  withConfirm,
  children,
  ...others
}: Props) => {
  const { open } = useOffsiteModal();
  const theme = useMantineTheme();
  const { sendUrlOpenedEvent } = useGaEvents();

  const withConfirmModal = useMemo(() => {
    return withConfirm ?? !isTrustedUrl(link);
  }, [link, withConfirm]);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    open(link);
  };

  return (
    <Anchor
      size={size}
      href={link}
      rel={'noopener noreferrer'}
      target={'_blank'}
      onClick={withConfirmModal ? onClick : () => sendUrlOpenedEvent(link)}
      {...others}
      c={linkColor || others.c?.toString()}
    >
      {label}
      {withIcon && (
        <IoOpenOutline
          className={classes.icon}
          color={others.c?.toString() || theme.other.colors.action}
        />
      )}
      {children}
    </Anchor>
  );
};
