import { useMoonChain } from 'hooks/useMoonChainConfig';
import { TokenAmount, TokenAmountProps } from '.';

type Props = Omit<TokenAmountProps, 'decimals' | 'symbol'>;

export const MoonTokenAmount = (props: Props) => {
  const chain = useMoonChain();

  return (
    <TokenAmount
      decimals={chain.decimals}
      symbol={chain.nativeAsset.originSymbol}
      {...props}
    />
  );
};
