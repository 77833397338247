import {
  dai,
  peaq,
  usdcwh,
  usdtwh,
  wbtc,
  weth,
} from '@moonbeam-network/xcm-config';
import { ParachainToken } from 'services/mrl/chains/classes/BaseChain';
import {
  moonbeamDAI,
  moonbeamPEAQ,
  moonbeamUSDCeth,
  moonbeamUSDT,
  moonbeamWBTC,
  moonbeamWETH,
} from 'services/mrl/tokens/chainTokens/mainnet/moonbeamTokens';

export const mrlWETH: ParachainToken = {
  asset: weth,
  name: 'Wrapped Ether',
  moonToken: moonbeamWETH,
};

// export const mrlGLMR: ParachainToken = {
//   asset: glmr,
//   moonToken: moonbeamGLMR,
// };

export const mrlDAI: ParachainToken = {
  asset: dai,
  name: 'Dai Stablecoin',
  moonToken: moonbeamDAI,
};

export const mrlUSDCWH: ParachainToken = {
  asset: usdcwh,
  name: 'USD Coin Wormhole',
  moonToken: moonbeamUSDCeth, // NOT SURE
};

export const mrlUSDT: ParachainToken = {
  asset: usdtwh,
  name: 'Tether USD Wormhole',
  moonToken: moonbeamUSDT,
};

export const mrlWBTC: ParachainToken = {
  asset: wbtc,
  name: 'Wrapped Bitcoin',
  moonToken: moonbeamWBTC,
};

export const mrlPEAQ: ParachainToken = {
  asset: peaq,
  moonToken: moonbeamPEAQ,
};
