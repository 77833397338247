import type { Signer } from '@polkadot/api/types';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import type { KeypairType } from '@polkadot/util-crypto/types';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isAddress } from 'viem';
import {
  shouldInitSubstrateExtensionsAtom,
  substrateAccountsAtom,
  substrateAddressAtom,
  substrateExtensionsAtom,
} from './atoms';
import {
  withSubstrateExtension,
  withSubstrateExtensionAccounts,
} from './selectors';
import {
  createGenesisFilter,
  createKeypairTypeFilter,
  getExtensions,
  getSigner,
  subscribeToAccounts,
} from './utils';

export function useInitSubstrateExtension(): void {
  const shouldInitExtensions = useRecoilValue(
    shouldInitSubstrateExtensionsAtom,
  );
  const [, setSubstrateExtensions] = useRecoilState(substrateExtensionsAtom);
  const [, setSubstrateAccounts] = useRecoilState(substrateAccountsAtom);

  useEffect(() => {
    if (!shouldInitExtensions) {
      return;
    }

    let unsubscribe: VoidFunction | undefined;

    getExtensions()
      .then(setSubstrateExtensions)
      .then(() => subscribeToAccounts(setSubstrateAccounts))
      // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
      .then((unsub) => (unsubscribe = unsub))
      .catch(console.error);

    return () => unsubscribe?.();
  }, [setSubstrateAccounts, setSubstrateExtensions, shouldInitExtensions]);
}

interface SubstrateAddressHook {
  address: string | undefined;
  setAddress: (address: string | undefined) => void;
}

interface UseSubstrateAddressParams {
  allowManualAddress?: boolean;
  genesisHash?: string;
  keypairType?: KeypairType;
}

export function useSubstrateAddress({
  allowManualAddress,
  genesisHash,
  keypairType,
}: UseSubstrateAddressParams = {}): SubstrateAddressHook {
  const [address, setAddress] = useRecoilState(substrateAddressAtom);
  const accounts = useSubstrateAccounts(genesisHash, keypairType);

  const finalAddress = useMemo(() => {
    if (!address || !accounts.length) {
      return undefined;
    }

    if (!allowManualAddress || genesisHash) {
      const isInAccounts = accounts.some((acc) => acc.address === address);

      return isInAccounts ? address : undefined;
    }

    if (keypairType === 'ethereum') {
      return address && isAddress(address) ? address : undefined;
    }

    return address;
  }, [accounts, address, allowManualAddress, genesisHash, keypairType]);

  return { address: finalAddress, setAddress };
}

export function useSubstrateAccounts(
  genesisHash?: string,
  keypairType?: KeypairType,
): InjectedAccountWithMeta[] {
  const { relay } = useMoonChain();
  const accounts = useRecoilValue(withSubstrateExtensionAccounts);

  return useMemo(
    () =>
      accounts
        .filter(createKeypairTypeFilter(keypairType))
        .filter(
          createGenesisFilter(
            genesisHash ? [genesisHash, relay.genesisHash] : [],
          ),
        ),
    [accounts, keypairType, genesisHash, relay.genesisHash],
  );
}

interface SubstrateExtensionHook {
  extensions: string[];
  selectedExtension: string | undefined;
  setSelectedExtension: (extension: string | undefined) => void;
  init: () => void;
}

export function useSubstrateExtension(): SubstrateExtensionHook {
  const extensions = useRecoilValue(substrateExtensionsAtom);
  const [selectedExtension, setSelectedExtension] = useRecoilState(
    withSubstrateExtension,
  );
  const [, setShouldInitExtensions] = useRecoilState(
    shouldInitSubstrateExtensionsAtom,
  );

  return {
    extensions,
    selectedExtension,
    setSelectedExtension,
    init: useCallback(
      () => setShouldInitExtensions(true),
      [setShouldInitExtensions],
    ),
  };
}

export function useSubstrateSigner(): Signer | undefined {
  const [signer, setSigner] = useState<Signer>();
  const extension = useRecoilValue(withSubstrateExtension);
  const extensions = useRecoilValue(substrateExtensionsAtom);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    getSigner(extension).then(setSigner).catch(console.error);
    // We need to add the extensions to the dependency array
    // to run hook again when the extensions are loaded
  }, [extension, extensions]);

  return signer;
}
