import { SubscanResponse } from 'services/subscan';

const RATE_LIMIT_ERROR_CODE = 20008;

async function performSubscanApiRequest(
  url: string,
  params: Record<string, string | number | undefined>,
  options: RequestInit = {},
  row = 100,
): Promise<SubscanResponse> {
  const headers = new Headers(options.headers || {});
  headers.append('Content-Type', 'application/json');

  const body = JSON.stringify({
    ...params,
    row,
  });

  const requestOptions: RequestInit = {
    ...options,
    body,
    headers,
    method: 'POST',
    redirect: 'follow',
  };

  return fetch(url, requestOptions).then((res) => res.json());
}

export async function fetchFromSubscanApiWithApiKeyRetry(
  url: string,
  params: Record<string, string | number | undefined>,
  row = 100,
): Promise<SubscanResponse> {
  const response = await performSubscanApiRequest(url, params, {}, row);

  if (response.code === RATE_LIMIT_ERROR_CODE) {
    // Retry with API key
    return performSubscanApiRequest(
      url,
      params,
      {
        headers: {
          'x-api-key': process.env.NEXT_PUBLIC_SUBSCAN_API_KEY || '',
        },
      },
      row,
    );
  }

  return response;
}

export async function fetchFromSubscanApi(
  url: string,
  params: Record<string, string | number | undefined>,
  row = 100,
): Promise<SubscanResponse> {
  return performSubscanApiRequest(url, params, {}, row);
}
