import { extractTime } from '@polkadot/util';
import { useLatestBlock } from 'hooks/queries/useLatestBlock';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { calcBlockRemainingTime } from 'utils/calc';

export function useBlockRemainingTime(
  targetBlock: number | undefined,
  timeUnitsDisplayed?: number,
): string | undefined {
  const latestBlock = useLatestBlock();
  const { blockTime } = useMoonChain();

  const timeDifference = useMemo(
    () =>
      targetBlock &&
      calcBlockRemainingTime(latestBlock ?? 0, targetBlock, blockTime),
    [latestBlock, blockTime, targetBlock],
  );

  return useTimeDelta(timeDifference, timeUnitsDisplayed);
}

export function useBlockTimestampElapsedTime(
  timestamp?: number,
  timeUnitsDisplayed?: number,
): string | undefined {
  return useTimeDelta(
    timestamp ? new Date().getTime() - timestamp * 1000 : 0,
    timeUnitsDisplayed,
  );
}

export function useBlockTime(
  numberOfBlocks?: number,
  timeUnitsDisplayed?: number,
): string | undefined {
  const { blockTime } = useMoonChain();

  return useTimeDelta(
    (numberOfBlocks || 0) * blockTime * 1000,
    timeUnitsDisplayed,
  );
}

function useTimeDelta(
  diffTime: number | undefined,
  timeUnitsDisplayed = 2,
): string | undefined {
  const { t } = useTranslation();
  const { days, hours, minutes, seconds } = extractTime(diffTime);

  if (!diffTime) return undefined;

  /**
   * based on Polkadotjs/apps's useBlockTime hook
   * https://github.com/polkadot-js/apps/blob/master/packages/react-hooks/src/useBlockTime.ts
   * */
  return `${diffTime < 0 ? '+' : ''}${[
    days ? t('timeDelta.day', { count: days }) : null,
    hours ? t('timeDelta.hour', { count: hours }) : null,
    minutes ? t('timeDelta.minute', { count: minutes }) : null,
    seconds ? t('timeDelta.second', { count: seconds }) : null,
  ]
    .filter((s): s is string => !!s)
    .slice(0, timeUnitsDisplayed)
    .join(' ')}`;
}
