import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import { TRPCError, TRPCProcedureType } from '@trpc/server';
import { DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import';
import { omit } from 'lodash';
import superjson from 'superjson';
import { parseJson } from 'utils/json';
import {
  BAD_REQUEST_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from 'utils/trpc/constants';
import { isWindowDefined } from 'utils/window';
import type { AppRouter } from '../../../pages/api/trpc/[trpc]';

function getBaseUrl(): string {
  if (isWindowDefined())
    // browser should use relative path
    return '';

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        httpBatchLink({
          transformer: superjson,
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
    };
  },
  transformer: superjson,
  /**
   * @link https://trpc.io/docs/ssr
   **/
  //   ssr: false,
});

type FormatterParam = {
  error: TRPCError;
  type: TRPCProcedureType | 'unknown';
  path: string | undefined;
  input: unknown;
  // biome-ignore lint/suspicious/noExplicitAny: could not get export for this type from tRPC....
  ctx: undefined | any;
  shape: DefaultErrorShape; // the default error shape
};

/**
 * This function encapsulates all the logic needed to transform the error shape.
 */
export function formatTRPCError(opts: FormatterParam) {
  // 1. Special handling for NOT_FOUND
  if (opts.error.code === NOT_FOUND_ERROR_CODE) {
    delete opts.shape.data.path;
    return {
      ...opts.shape,
      message: 'Not found',
    };
  }

  const currentMessage = opts.shape.message;

  if (
    opts.error.code === BAD_REQUEST_ERROR_CODE &&
    currentMessage.includes('is not valid JSON')
  ) {
    const newMsg = `input is not valid JSON`;

    return {
      ...opts.shape,
      message: newMsg,
    };
  }

  // 3. Attempt to parse JSON
  try {
    const parsedError = parseJson(currentMessage);

    if (!Array.isArray(parsedError)) {
      return opts.shape;
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const cleaned = parsedError.map((value: any) => {
      if (typeof value !== 'object') {
        return value;
      }
      const currentCleaned = omit(value, ['received']);
      currentCleaned.message = currentCleaned.message
        .replace(/received\s+'[^']*'/, '')
        .trim();
      return currentCleaned;
    });

    const newMsg = JSON.stringify(cleaned);

    return {
      ...opts.shape,
      message: newMsg,
      data: {
        ...opts.shape.data,
        message: newMsg,
      },
    };
  } catch (parsingError) {
    // 4. If JSON parse fails, return the original shape (or do any fallback transformation)
    console.error(
      'Got error while errorFormatter for tRPC',
      parsingError,
      opts,
    );
    return opts.shape;
  }
}
