import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { favoriteTokensAtom } from './atoms';

interface UseFavoriteTokensResponse {
  addFavoriteToken: (id: string) => void;
  checkFavoriteToken: (id: string) => boolean;
  favoriteTokens: string[];
  removeFavoriteToken: (id: string) => void;
}

export function useFavoriteTokens(): UseFavoriteTokensResponse {
  const { key, nativeAsset } = useMoonChain();

  const [favoriteTokens, setFavoriteTokens] =
    useRecoilState(favoriteTokensAtom);

  const addFavoriteToken = useCallback(
    (id: string) => {
      setFavoriteTokens((prevFavs) => {
        if (prevFavs[key].includes(id)) return prevFavs;

        return {
          ...prevFavs,
          [key]: [...prevFavs[key], id],
        };
      });
    },
    [setFavoriteTokens, key],
  );

  const checkFavoriteToken = useCallback(
    (id: string) => favoriteTokens[key].includes(id),
    [favoriteTokens, key],
  );

  const removeFavoriteToken = useCallback(
    (id: string) => {
      if (id === nativeAsset.address) {
        return;
      }

      setFavoriteTokens((prevFavs) => {
        return {
          ...prevFavs,
          [key]: prevFavs[key].filter((x) => x !== id),
        };
      });
    },
    [key, nativeAsset.address, setFavoriteTokens],
  );

  return {
    addFavoriteToken,
    checkFavoriteToken,
    favoriteTokens: favoriteTokens[key],
    removeFavoriteToken,
  };
}
