import {
  BaseChainConstructorParams,
  Ecosystem,
  MrlBaseChain,
  MrlChainType,
  MrlDestination,
  MrlTransferrableAssets,
} from 'services/mrl/chains/classes/BaseChain';
import { Except } from 'type-fest';

interface MrlEvmChainConstructorParams
  extends Except<
    BaseChainConstructorParams,
    | 'parachainId'
    | 'ss58Format'
    | 'ws'
    | 'genesisHash'
    | 'isEvm'
    | 'ecosystem'
    | 'type'
  > {
  chainId: number;
  destinations: MrlDestination[];
  transferrableAssets: MrlTransferrableAssets[];
}

export class MrlEvmChain extends MrlBaseChain {
  readonly chainId: number;
  readonly destinations: MrlDestination[];
  readonly transferrableAssets: MrlTransferrableAssets[];

  constructor({
    chainId,
    destinations,
    transferrableAssets,
    ...others
  }: MrlEvmChainConstructorParams) {
    super({
      ...others,
      isEvm: true,
      type: MrlChainType.EvmChain,
      ecosystem: Ecosystem.EvmChains,
    });

    this.chainId = chainId;
    this.destinations = destinations;
    this.transferrableAssets = transferrableAssets;
  }

  useSubstrateWallet(): boolean {
    return false;
  }
}
