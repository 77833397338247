import {
  Ecosystem,
  MrlBaseChain,
  MrlChainType,
  MrlDestination,
  MrlTransferrableAssets,
} from 'services/mrl/chains/classes/BaseChain';
import { EvmParachainConstructorParams } from 'services/mrl/chains/classes/MrlEvmParachain';
import { Except } from 'type-fest';

interface MrlMoonChainConstructorParams
  extends Except<
    EvmParachainConstructorParams,
    | 'ecosystem'
    | 'destinations'
    | 'transferrableAssets'
    | 'genesisHash'
    | 'whName'
  > {
  chainId: number;
  destinations: MrlDestination[];
  transferrableAssets: MrlTransferrableAssets[];
}

export class MrlMoonChain extends MrlBaseChain {
  readonly chainId: number;
  readonly destinations: MrlDestination[];
  readonly transferrableAssets: MrlTransferrableAssets[];

  constructor({
    chainId,
    destinations,
    transferrableAssets,
    ...others
  }: MrlMoonChainConstructorParams) {
    super({
      ...others,
      isEvm: true,
      type: MrlChainType.MoonChain,
      ecosystem: Ecosystem.Dotsama,
      whName: 'Moonbeam',
    });

    this.chainId = chainId;
    this.destinations = destinations;
    this.transferrableAssets = transferrableAssets;
  }

  useSubstrateWallet(): boolean {
    return false;
  }
}
