import { MrlToken } from 'services/mrl/tokens/Token';

export const ethereumETH = MrlToken.create({
  symbol: 'ETH',
  decimals: 18,
  isNative: true,
  wrappedKey: 'WETH',
  chain: 'Ethereum',
  key: 'eth',
  name: 'Ether',
});

// export const ethereumWETH = MrlToken.create({
//   symbol: 'WETH',
//   decimals: 18,
//   address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
//   chain: 'Ethereum',
//   key: 'weth',
//   name: 'WETH',
// });

export const ethereumUSDCeth = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  chain: 'Ethereum',
  key: 'usdc',
  name: 'USD Coin',
});

export const ethereumWBTC = MrlToken.create({
  symbol: 'WBTC',
  decimals: 8,
  address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  chain: 'Ethereum',
  key: 'wbtc',
  name: 'WBTC',
});

export const ethereumUSDT = MrlToken.create({
  symbol: 'USDT',
  decimals: 6,
  address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  chain: 'Ethereum',
  key: 'usdt',
  name: 'Tether USD',
});

export const ethereumDAI = MrlToken.create({
  symbol: 'DAI',
  decimals: 18,
  address: '0x6b175474e89094c44da98b954eedeac495271d0f',
  chain: 'Ethereum',
  key: 'dai',
  name: 'Dai Stablecoin',
});

// export const ethereumWFTM = MrlToken.create({
//   symbol: 'WFTM',
//   decimals: 18,
//   address: '0x4cD2690d86284e044cb63E60F1EB218a825a7e92',
//   original: 'Fantom',
//   chain: 'Ethereum',
//   key: 'wftm',
//   name: 'WFTM',
// });

export const ethereumWGLMR = MrlToken.create({
  symbol: 'WGLMR',
  decimals: 18,
  address: '0x93d3696A9F879b331f40CB5059e37015423A3Bd0',
  original: 'Moonbeam',
  chain: 'Ethereum',
  key: 'wglmr',
  name: 'WGLMR',
});

export const ethereumPEAQ = MrlToken.create({
  symbol: 'PEAQ',
  decimals: 18,
  address: '0x6e642b4dfe787b8f101d1fb66c2ef56e2b4c6c52',
  original: 'peaq',
  chain: 'Ethereum',
  key: 'peaq',
  name: 'PEAQ',
});
