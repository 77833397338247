import { AssetAmount, ChainAsset } from '@moonbeam-network/xcm-types';
import {
  PalletAssetsAssetAccount,
  PalletAssetsAssetMetadata,
} from '@polkadot/types/lookup';
import { useAddress } from 'hooks/useAddress';
import { useApi } from 'hooks/useApi';
import { useApiCall } from 'hooks/useApiCall';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useMemo } from 'react';

function useXc20AssetsData() {
  const chain = useMoonChain();
  const { ids, assets } = useMemo(
    () =>
      Array.from(chain.assets.values())
        .filter((asset) => !chain.isNative(asset) && asset.ids?.id)
        .reduce(
          (acc, asset) => {
            acc.ids.push(asset.ids?.id as string);
            acc.assets.push(asset);

            return acc;
          },
          { ids: [] as string[], assets: [] as ChainAsset[] },
        ),
    [chain],
  );

  return { ids, assets };
}

function useBalances(ids?: string[]): PalletAssetsAssetAccount[] | undefined {
  const api = useApi();
  const address = useAddress();

  const query = useMemo(() => {
    if (!api || !address || !ids) {
      return undefined;
    }

    return ids.map((id) => [api.query.assets.account, [id, address]]);
  }, [address, api, ids]);

  return useApiCall<PalletAssetsAssetAccount[]>(api?.queryMulti, [query]);
}

function useMeta(ids?: string[]): PalletAssetsAssetMetadata[] | undefined {
  const api = useApi();

  const query = useMemo(() => {
    if (!api || !ids) {
      return undefined;
    }

    return ids.map((id) => [api.query.assets.metadata, [id]]);
  }, [api, ids]);

  return useApiCall<PalletAssetsAssetMetadata[]>(api?.queryMulti, [query]);
}

export function useXc20Balances(): AssetAmount[] | undefined {
  const { ids, assets } = useXc20AssetsData();
  const metas = useMeta(ids);
  const balances = useBalances(ids);

  return useMemo(() => {
    if (!metas) {
      return undefined;
    }

    return assets.map((asset, index) => {
      const meta = metas[index];
      const balance = balances?.[index];

      return AssetAmount.fromChainAsset(
        asset.copyWith({
          symbol: meta?.symbol.toHuman()?.toString() || asset.originSymbol,
        }),
        {
          amount: balance?.balance.toBigInt() || 0n,
        },
      );
    });
  }, [assets, balances, metas]);
}
