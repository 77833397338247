import { peaqEvm, peaqEvmAlphanet } from '@moonbeam-network/xcm-config';
import { defineChain } from 'viem';

export const peaqEvmAlphanetViemChain = defineChain({
  id: peaqEvmAlphanet.id,
  name: peaqEvmAlphanet.name,
  nativeCurrency: {
    name: peaqEvmAlphanet.nativeAsset.originSymbol,
    symbol: peaqEvmAlphanet.nativeAsset.originSymbol,
    decimals: peaqEvmAlphanet.nativeAsset.decimals,
  },
  rpcUrls: {
    default: { http: [peaqEvmAlphanet.rpc] },
  },
});

export const peaqEvmViemChain = defineChain({
  id: peaqEvm.id,
  name: peaqEvm.name,
  nativeCurrency: {
    name: peaqEvm.nativeAsset.originSymbol,
    symbol: peaqEvm.nativeAsset.originSymbol,
    decimals: peaqEvm.nativeAsset.decimals,
  },
  rpcUrls: {
    default: { http: [peaqEvm.rpc] },
  },
});
