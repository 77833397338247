import { useWalletNotifications } from 'hooks/notifications/useWalletNotifications';
import { Wallet, walletsCanAddTokens } from 'lib/wallets';
import { useCallback } from 'react';
import { useAccount } from 'wagmi';

interface UseAddTokenToWalletReturn {
  addToWallet: (address: string, decimals: number, symbol: string) => void;
  canAddTokens: boolean;
}

export function useAddTokenToWallet(): UseAddTokenToWalletReturn {
  const { connector } = useAccount();
  const { failedToAddAsset } = useWalletNotifications();
  const canAddTokens = walletsCanAddTokens.includes(connector?.name as Wallet);

  const addToWallet = useCallback(
    (address: string, decimals: number, symbol: string) => {
      connector
        ?.getProvider()
        .then((provider) =>
          // @ts-expect-error getProvider returns `unknown`
          provider?.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address,
                symbol,
                decimals,
              },
            },
          }),
        )
        .catch((error) => {
          console.log(error);
          failedToAddAsset(symbol);
        });
    },
    [connector, failedToAddAsset],
  );

  return { addToWallet, canAddTokens };
}
