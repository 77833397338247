import {
  Loader,
  Text,
  TextProps,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { AssetAmount } from '@moonbeam-network/xcm-types';
import { useFiatConversion } from 'hooks/useFiatConversion';
import { useIsMounted } from 'hooks/useIsMounted';
import { useTranslation } from 'next-i18next';
import { Suspense } from 'react';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { useUserSettings } from 'recoil/userSettings';

interface Props extends TextProps {
  assetAmount: AssetAmount;
  showPrice?: boolean;
  maxDecimals?: number;
  isHidable?: boolean;
}

function TokenFiatPriceContent({
  assetAmount,
  maxDecimals,
  showPrice = false,
  isHidable = false,
  ...textProps
}: Props) {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { areBalancesVisible } = useUserSettings();
  const [fiatConversion, fiatSymbol] = useFiatConversion(
    assetAmount,
    maxDecimals,
    showPrice,
  );

  if (!isMounted) {
    return null;
  }

  const isHidden = isHidable && !areBalancesVisible;

  return (
    <Tooltip disabled={!!fiatConversion || isHidden} label={t('priceNotFound')}>
      <Text span {...textProps}>
        {fiatSymbol}
        {isHidden ? (
          '****'
        ) : fiatConversion ? (
          fiatConversion
        ) : (
          <>
            {'-.- '}
            <IoHelpCircleOutline style={{ verticalAlign: 'middle' }} />
          </>
        )}
      </Text>
    </Tooltip>
  );
}

export function TokenFiatPrice(props: Props) {
  const theme = useMantineTheme();

  return (
    <Suspense fallback={<Loader size={'xs'} color={theme.colors.gray[4]} />}>
      <TokenFiatPriceContent {...props} />
    </Suspense>
  );
}
