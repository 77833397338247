import {
  AnyChain,
  Asset,
  AssetAmount,
  ChainAsset,
  EvmParachain,
  EvmParachainConstructorParams,
  Parachain,
} from '@moonbeam-network/xcm-types';

interface MoonChainConstructorParams extends EvmParachainConstructorParams {
  blockTime: number;
  explorerUrls: string[];
  minBalance: bigint;
  polkassemblyOpenGovUrl?: string;
  polkassemblyApiParam?: string;
  polkassemblyUrl: string;
  relay: Parachain;
  rpcUrls: string[];
  subscanApiUrl: string;
  subscanUrl: string;
}

export class MoonChain extends EvmParachain {
  blockTime: number;
  decimals = 18;
  explorerUrls: string[];
  minBalance: bigint;
  polkassemblyOpenGovUrl?: string;
  polkassemblyApiParam: string;
  polkassemblyUrl: string;
  relay: Parachain;
  rpcUrls: string[];
  subscanApiUrl: string;
  subscanUrl: string;

  constructor({
    blockTime,
    explorerUrls,
    minBalance,
    polkassemblyOpenGovUrl,
    polkassemblyUrl,
    polkassemblyApiParam,
    relay,
    rpcUrls,
    subscanApiUrl,
    subscanUrl,
    ...others
  }: MoonChainConstructorParams) {
    super(others);

    this.blockTime = blockTime;
    this.explorerUrls = explorerUrls;
    this.minBalance = minBalance;
    this.polkassemblyOpenGovUrl = polkassemblyOpenGovUrl;
    this.polkassemblyUrl = polkassemblyUrl;
    this.polkassemblyApiParam = polkassemblyApiParam || this.key;
    this.relay = relay;
    this.rpcUrls = rpcUrls;
    this.subscanApiUrl = subscanApiUrl;
    this.subscanUrl = subscanUrl;
  }

  isNative(asset: Asset | ChainAsset | AssetAmount): boolean {
    return this.nativeAsset.isEqual(asset);
  }

  isRelayChain(chain: AnyChain): boolean {
    return this.relay.key === chain.key;
  }

  getErc20Address(asset: Asset | ChainAsset | AssetAmount): string {
    const chainAsset = this.getChainAsset(asset);

    if (!chainAsset.address) {
      throw new Error(`Can't get ERC20 id for asset ${asset.key}`);
    }

    return chainAsset.address;
  }
}
