import { moonChainConfigs } from 'configs/chains';
import {
  SubscanListResponse,
  SubscanReward,
  fetchFromSubscanApi,
  fetchFromSubscanApiWithApiKeyRetry,
} from 'services/subscan';

// ! should be used just in backend
export async function fetchSubscanRewardsWithApiKeyRetry(
  address: string,
  moonChainKey: string,
  cutoffTimestamp: number,
): Promise<SubscanReward[]> {
  const { subscanApiUrl } = moonChainConfigs[moonChainKey];

  let page = 0;
  const rewards: SubscanReward[] = [];

  do {
    const response = await fetchFromSubscanApiWithApiKeyRetry(
      `${subscanApiUrl}/scan/account/reward_slash`,
      { address, page: page++ },
    );
    const { list } = response.data as SubscanListResponse;

    if (!list || !list.length) break;

    rewards.push(...(list as SubscanReward[]));
  } while (rewards[rewards.length - 1].block_timestamp > cutoffTimestamp);

  return rewards;
}

export async function fetchSubscanRewardsWithoutRetry(
  address: string,
  moonChainKey: string,
  cutoffTimestamp: number,
): Promise<SubscanReward[]> {
  const { subscanApiUrl } = moonChainConfigs[moonChainKey];
  let page = 0;
  const rewards: SubscanReward[] = [];

  do {
    const response = await fetchFromSubscanApi(
      `${subscanApiUrl}/scan/account/reward_slash`,
      { address, page: page++ },
    );
    const { list } = response.data as SubscanListResponse;

    if (!list || !list.length) break;

    rewards.push(...(list as SubscanReward[]));
  } while (rewards[rewards.length - 1].block_timestamp > cutoffTimestamp);

  return rewards;
}
