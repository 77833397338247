import {
  glmr,
  moonbaseAlpha,
  moonbeam,
  moonriver,
} from '@moonbeam-network/xcm-config';
import { localDevConfig } from 'configs/chains';
import { atom } from 'recoil';
import { createLocalStorageEffect } from 'recoil/effects';

const id = moonbeam.getChainAsset(glmr).address as string;

export const favoriteTokensAtom = atom<Record<string, string[]>>({
  key: 'favoriteTokens',
  default: {
    [moonbeam.key]: [id],
    [moonriver.key]: [id],
    [moonbaseAlpha.key]: [id],
    [localDevConfig.key]: [id],
  },
  effects: [createLocalStorageEffect('favorite-tokens')],
});
