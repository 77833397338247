import { MantineColorsTuple } from '@mantine/core';
import {
  PalletReferendaCurve,
  PalletReferendaTrackInfo,
} from '@polkadot/types/lookup';
import Big from 'big.js';
import { ChartData } from 'components/pages/open-gov/StatsPanel/ReferendaChart';
import { type ReferendaStatistics } from 'hooks/queries/open-gov/useReferendaStatistics';
import { CurveGraph, Tracks } from 'hooks/queries/useTracks';
import { TFunction } from 'next-i18next';
import { ReferendumStatus } from 'types/governance';
import { ProposalInformation } from 'types/governance/proposalInformation';
import { bigMax, bigMin } from 'utils/calc';

const THRESHOLDS_CURVE_LENGTH = 500;
const THRESHOLDS_CURVE_MAX = 1_000_000_000;

export function getDiagonalPattern(
  colorOne: string,
  colorTwo: string,
): CanvasPattern | string {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 150;
  canvas.height = 150;

  if (context) {
    context.fillStyle = colorOne;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.lineWidth = 5;
    context.strokeStyle = colorTwo;

    for (let i = 1; i < 30; i++) {
      context.beginPath();
      context.moveTo(0, i * 14);
      context.lineTo(i * 14, 0);
      context.stroke();
    }
  }

  return context?.createPattern(canvas, 'repeat') ?? colorOne;
}

export function formatDateShort(date: Date, locale: string): string {
  return date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
  });
}

export function buildReferendaChartData(
  tracks: Tracks | undefined,
  statistics: ReferendaStatistics | undefined,
  chartColors: string[] | MantineColorsTuple,
  t: TFunction,
): ChartData {
  const colors: string[] = [];
  const labels: string[] = [];
  const referendaCounts: number[] = [];

  tracks?.forEach(({ name, id }) => {
    colors.push(chartColors[id]);
    referendaCounts.push(statistics?.referendaCountByTrack.get(name) || 0);
    labels.push(t(`governance:${name}`));
  });

  return {
    colors,
    labels,
    referendaCounts,
  };
}

/**
 * Taken from polkadot-js apps calculations
 * https://github.com/polkadot-js/apps/blob/master/packages/page-referenda/src/util.ts
 */
export function calcThresholdCurves({
  decisionPeriod,
  minApproval,
  minSupport,
}: PalletReferendaTrackInfo): CurveGraph {
  const approval = new Array<Big>(THRESHOLDS_CURVE_LENGTH);
  const support = new Array<Big>(THRESHOLDS_CURVE_LENGTH);
  const timeline = new Array<Big>(THRESHOLDS_CURVE_LENGTH);
  const decisionPeriodBig = Big(decisionPeriod.toString());

  const step = decisionPeriodBig
    .div(THRESHOLDS_CURVE_LENGTH)
    .round(0, Big.roundDown);

  const last = THRESHOLDS_CURVE_LENGTH - 1;
  let current = new Big(0);

  for (let i = 0; i < last; i++) {
    approval[i] = curveThreshold(minApproval, current, decisionPeriodBig);
    support[i] = curveThreshold(minSupport, current, decisionPeriodBig);
    timeline[i] = current;

    current = current.add(step);
  }

  approval[last] = curveThreshold(
    minApproval,
    decisionPeriodBig,
    decisionPeriodBig,
  );
  support[last] = curveThreshold(
    minSupport,
    decisionPeriodBig,
    decisionPeriodBig,
  );
  timeline[last] = decisionPeriodBig;

  return {
    approval: reduceToPercentage(approval),
    support: reduceToPercentage(support),
    timeline: timeline.map((element) => element.toNumber()),
  };
}

function curveThreshold(
  curve: PalletReferendaCurve,
  input: Big,
  xMaxValue: Big,
): Big {
  // if divisor is zero, we return the max
  if (xMaxValue === Big(0)) {
    return Big(THRESHOLDS_CURVE_MAX);
  }

  const xValue = input
    .mul(THRESHOLDS_CURVE_MAX)
    .div(xMaxValue)
    .round(0, Big.roundDown);

  if (curve.isLinearDecreasing) {
    const { ceil, floor, length } = curve.asLinearDecreasing;

    const lengthBig = Big(length.toString());
    const ceilBig = Big(ceil.toString());
    const floorBig = Big(floor.toString());

    const min = bigMin(xValue, lengthBig);

    return ceilBig.sub(min.mul(ceilBig.sub(floorBig)).div(lengthBig));
  } else if (curve.isSteppedDecreasing) {
    const { begin, end, period, step } = curve.asSteppedDecreasing;

    const beginBig = Big(begin.toString());
    const endBig = Big(end.toString());
    const periodBig = Big(period.toString());
    const stepBig = Big(step.toString());

    const calc = stepBig.mul(xValue).div(periodBig);

    return bigMax(endBig, beginBig.sub(bigMin(beginBig, calc)));
  } else if (curve.isReciprocal) {
    const { factor, xOffset, yOffset } = curve.asReciprocal;
    const div = xValue.add(Big(xOffset.toString()));

    if (div === Big(0)) {
      return Big(THRESHOLDS_CURVE_MAX);
    }

    return bigMin(
      Big(THRESHOLDS_CURVE_MAX),
      Big(factor.toString())
        .mul(THRESHOLDS_CURVE_MAX)
        .div(div)
        .add(yOffset.toString()),
    );
  }

  throw new Error(`Unknown curve found ${curve.type}`);
}

function reduceToPercentage(bigArray: Big[]): number[] {
  return bigArray.map((element) =>
    element.div(THRESHOLDS_CURVE_MAX).mul(100).toNumber(),
  );
}

export function getCurrentBlockInTime(
  latestBlock: number | undefined,
  referendum: ProposalInformation,
  timelineData: number[],
): number {
  // out of the chart
  let currentIndex = -1;

  if (latestBlock && referendum.status === ReferendumStatus.DECIDING) {
    for (let index = 0; index < THRESHOLDS_CURVE_LENGTH; index++) {
      if (referendum.latestBlock) {
        const element = timelineData[index] + referendum.latestBlock;

        if (element > latestBlock) {
          currentIndex = index;
          break;
        }
      }
    }
  }

  return currentIndex;
}
