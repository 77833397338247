import { AvatarProps, Indicator, Tooltip, UnstyledButton } from '@mantine/core';
import { Jazzicon } from 'components/Jazzicon';
import { useClipboard } from 'hooks/useClipboard';
import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';
import classes from './Avatar.module.css';
import { Checkmark } from './Checkmark';
import { LowQuality } from './LowQuality';

interface Props extends AvatarProps {
  address?: string;
  withCheckmark?: boolean;
  withLowQualitySign?: boolean;
  size?: number;
}

export const Avatar = ({
  address = '',
  size,
  withCheckmark = false,
  withLowQualitySign = false,
  ...others
}: Props) => {
  const clipboard = useClipboard();
  const { t } = useTranslation('');

  const copyAddress: MouseEventHandler<HTMLButtonElement> = (event) => {
    clipboard.copy(address);
    event.stopPropagation();
  };

  return (
    <Tooltip
      label={t('copyAddress')}
      position={'right'}
      disabled={clipboard.copied}
      withArrow
    >
      <UnstyledButton aria-label={t('copyAddress')} onClick={copyAddress}>
        <Indicator
          zIndex={10}
          position={'bottom-end'}
          offset={3}
          disabled={!withCheckmark && !withLowQualitySign}
          label={
            withCheckmark ? (
              <Checkmark />
            ) : withLowQualitySign ? (
              <LowQuality />
            ) : undefined
          }
          classNames={{
            root: classes.indicator,
          }}
        >
          <Jazzicon
            address={address}
            size={size}
            paperStyles={{
              border: `2px solid var(--mantine-primary-color-filled)`,
            }}
            {...others}
          />
        </Indicator>
      </UnstyledButton>
    </Tooltip>
  );
};
