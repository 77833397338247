export enum SubscanReferendumStatus {
  SUBMITTED = 'Submitted',
  DECISION = 'Decision',
  CONFIRM_STARTED = 'ConfirmStarted',
  CONFIRM = 'Confirm',
  APPROVED = 'Approved',
  CONFIRM_ABORTED = 'ConfirmAborted',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  TIMEOUT = 'Timeout',
  KILLED = 'Killed',
  EXECUTED = 'Executed',
  EXECUTED_FAILED = 'ExecutedFailed',
}

export enum ReferendumStatus {
  SUBMITTED = 'Submitted',
  DECIDING = 'Deciding',
  CONFIRMING = 'Confirming',
  CONFIRMED = 'Confirmed',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  TIMED_OUT = 'TimedOut',
  CANCELLED = 'Cancelled',
  KILLED = 'Killed',
}

export enum ReferendumFailingReason {
  NOT_ENOUGH_APPROVAL = 'not_enough_approval',
  NOT_ENOUGH_SUPPORT = 'not_enough_support',
}

export type ReferendumPassingStatus = {
  isPassing: boolean;
  failingReason?: ReferendumFailingReason;
  whenBlock: number;
};
