import { MrlToken } from 'services/mrl/tokens/Token';

// these tokens are generated from Wormhole SDK
// const wh = new Wormhole(env, [EvmPlatform]);
// const fantomWhCHain = wh.getChain('Fantom');
// fantomWhCHain.config.tokenMap

// export const fantomWETH = MrlToken.create({
//   symbol: 'WETH',
//   decimals: 18,
//   address: '0x2A126f043BDEBe5A0A9841c51915E562D9B07289',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'weth',
//   name: 'Wrapped Ether',
// });

// export const fantomUSDCeth = MrlToken.create({
//   symbol: 'USDC',
//   decimals: 6,
//   address: '0x2Ec752329c3EB419136ca5e4432Aa2CDb1eA23e6',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   name: 'USDCeth',
//   key: 'usdc',
// });

// export const fantomWBTC = MrlToken.create({
//   symbol: 'WBTC',
//   decimals: 8,
//   address: '0x87e9E225aD8a0755B9958fd95BE43DD6A91FF3A7',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'wbtc',
//   name: 'WBTC',
// });

// export const fantomUSDT = MrlToken.create({
//   symbol: 'USDT',
//   decimals: 6,
//   address: '0x14BCb86aEed6a74D3452550a25D37f1c30AA0A66',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'usdt',
//   name: 'USDT',
// });

// export const fantomDAI = MrlToken.create({
//   symbol: 'DAI',
//   decimals: 18,
//   address: '0xEE786D3D73Ea645365c7248E4e40eDba08B1169F',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'dai',
//   name: 'DAI',
// });

export const fantomFTM = MrlToken.create({
  symbol: 'FTM',
  decimals: 18,
  isNative: true,
  wrappedKey: 'WFTM',
  chain: 'Fantom',
  key: 'ftm',
  name: 'FTM',
});

// export const fantomWFTM = MrlToken.create({
//   symbol: 'WFTM',
//   decimals: 18,
//   address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
//   chain: 'Fantom',
//   key: 'wftm',
//   name: 'WFTM',
// });

export const fantomWGLMR = MrlToken.create({
  symbol: 'WGLMR',
  decimals: 18,
  address: '0xBF227E92D6754EB4BFE26C40cb299ff2809Da45f',
  original: 'Moonbeam',
  chain: 'Fantom',
  key: 'wglmr',
  name: 'WGLMR',
});
