import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useRouter } from 'next/router';
import { buildFullPath, checkCurrentPath } from 'utils/routes';

interface RouteLink {
  isCurrentPath: boolean;
  fullPath: string;
}

export function useRouteLink(path?: string): RouteLink {
  const moonChainKey = useMoonChainKeyParam();
  const router = useRouter();

  const fullPath = buildFullPath(moonChainKey, path);
  const isCurrentPath = checkCurrentPath(router, fullPath, path);

  return {
    isCurrentPath,
    fullPath,
  };
}
