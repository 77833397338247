export function convertEnumToValueList(anyEnum: {
  [s: number]: string;
}): string[] {
  return Object.values(anyEnum).filter((v) => isNaN(Number(v)));
}

export function getEnumValueIndex(
  anyEnum: {
    [s: number]: string;
  },
  value: string,
): number {
  return Object.values(anyEnum).indexOf(value);
}

function isDefined<T>(param: T | null | undefined): param is T {
  return param !== undefined && param !== null;
}

export function isDefinedRecursive(params: unknown[]): boolean {
  return params.every((param) => {
    if (Array.isArray(param)) {
      return isDefinedRecursive(param);
    }

    if (typeof param === 'object') {
      return isDefinedRecursive(Object.values(param || {}));
    }

    return isDefined(param);
  });
}
