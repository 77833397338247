import { TextProps } from '@mantine/core';
import { AssetAmount } from '@moonbeam-network/xcm-types';
import { TokenFiatPrice } from 'components/TokenFiatPrice/TokenFiatPrice';
import { useMoonChain } from 'hooks/useMoonChainConfig';

interface Props extends TextProps {
  amount: bigint;
  maxDecimals?: number;
  isHidable?: boolean;
}

export function MoonTokenFiatPrice({ amount, ...props }: Props) {
  const { nativeAsset } = useMoonChain();

  const assetAmount = AssetAmount.fromChainAsset(nativeAsset, {
    amount,
  });

  return <TokenFiatPrice assetAmount={assetAmount} {...props} />;
}
