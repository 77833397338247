import { Container } from '@mantine/core';
import { MenuItemProps } from 'components/AppShell/Navbar/NavbarMenu/MenuItem';
import { useIsAppDirectoryDisabledFlag } from 'hooks/flags/useIsAppDirectoryDisabledFlag';
import { useIsMrlDisabledFlag } from 'hooks/flags/useIsMrlDisabledFlag';
import { useIsProxyPageDisabledFlag } from 'hooks/flags/useIsProxyPageDisabledFlag';
import { useIsSquidDisabledFlag } from 'hooks/flags/useIsSquidDisabledFlag';
import { useTracks } from 'hooks/queries/useTracks';
import { useIsMoonbeam, useMoonChainEcosystem } from 'hooks/useMoonChainConfig';
import { useOffsiteLinks } from 'hooks/useOffsiteLinks';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { BiCoinStack, BiTransfer } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import { CiCreditCard1 } from 'react-icons/ci';
import { MdOutlineHowToVote } from 'react-icons/md';
import { SiEnvoyproxy } from 'react-icons/si';
import { TbPlant, TbSmartHome } from 'react-icons/tb';
import {
  ethereumMainnetChain,
  moonbeamMrlChain,
} from 'services/mrl/chains/mainnet';
import { getReferendaTracksIconOrDefault } from 'utils/theme';
import { MenuCategory, MenuCategoryProps } from './MenuCategory';

export const mrlPath = 'mrl';
export const xcmPath = 'xcm';

const appDirItems = [
  {
    label: 'appDirCategories.overview',
    path: `app-dir`,
  },
  {
    label: 'appDirCategories.defi',
    path: `app-dir?cat=defi`,
  },
  {
    label: 'appDirCategories.dex',
    path: `app-dir?cat=dex`,
  },
  {
    label: 'appDirCategories.bridges',
    path: `app-dir?cat=bridges`,
  },
  {
    label: 'appDirCategories.lending',
    path: `app-dir?cat=lending`,
  },
  {
    label: 'appDirCategories.nfts',
    path: `app-dir?cat=nfts`,
  },
  {
    label: 'appDirCategories.gaming',
    path: `app-dir?cat=gaming`,
  },
  {
    label: 'appDirCategories.social',
    path: `app-dir?cat=social`,
  },
  {
    label: 'appDirCategories.wallets',
    path: `app-dir?cat=wallets`,
  },
  {
    label: 'appDirCategories.dao',
    path: `app-dir?cat=dao`,
  },
  {
    label: 'appDirCategories.other',
    path: `app-dir?cat=other`,
  },
] satisfies MenuItemProps[];

const onRampItems: MenuItemProps[] = [
  { label: 'transak', link: 'https://global.transak.com/' },
  {
    label: 'onrampMoney',
    link: 'https://onramp.money/main/buy/?appId=1&coinCode=glmr&network=glmr&fiatType=12',
  },
  { label: 'guardarian', link: 'https://guardarian.com/buy-glmr' },
];

export const NavbarMenu = () => {
  const { t } = useTranslation();
  const isMoonbeam = useIsMoonbeam();

  const mrlMenuPath = !isMoonbeam
    ? mrlPath
    : `mrl?from=${ethereumMainnetChain.key}&to=${moonbeamMrlChain.key}`;
  const xcmLabel = useMoonChainEcosystem();

  const {
    bridges,
    collators,
    orbiter,
    alerts,
    signalVoting,
    discussions,
    squid,
  } = useOffsiteLinks();

  const openGovTracks = useTracks();
  const isAppDirDisabled = useIsAppDirectoryDisabledFlag();
  const isMrlDisabled = useIsMrlDisabledFlag();
  const isProxyPageDisabled = useIsProxyPageDisabledFlag();
  const isSquidDisabled = useIsSquidDisabledFlag();

  const stakingItems: MenuItemProps[] = useMemo(() => {
    return [
      { label: 'overview', path: 'staking' },
      { label: 'collators', link: collators },
      { label: 'orbiterProgram', link: orbiter },
    ];
  }, [collators, orbiter]);

  const crossChainItems: MenuItemProps[] = useMemo(() => {
    const items = [
      { label: xcmLabel, path: xcmPath },
      ...(!isSquidDisabled ? [{ label: 'Squid', link: squid }] : []),
      ...(bridges ? [{ label: 'otherBridges', link: bridges }] : []),
    ];

    if (!isMrlDisabled) {
      // push as first item
      items.unshift({ label: 'external', path: mrlMenuPath });
    }

    return items;
  }, [bridges, isMrlDisabled, squid, isSquidDisabled, mrlMenuPath, xcmLabel]);

  const governanceLinkItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        label: 'signalVoting',
        link: signalVoting,
        tooltip: t('signalVotingTooltip'),
      },
      { label: 'discussions', link: discussions },
      ...(alerts ? [{ label: 'alerts', link: alerts }] : []),
    ];
  }, [alerts, discussions, signalVoting, t]);

  const openGovItems: MenuItemProps[] = useMemo(() => {
    const govItems: MenuItemProps[] = [{ label: 'overview', path: 'open-gov' }];

    openGovTracks?.forEach((track) => {
      govItems.push({
        label: `governanceTracks.${track.name}`,
        path: `open-gov?track=${track.name}`,
        icon: getReferendaTracksIconOrDefault(track.name),
      });
    });

    return govItems;
  }, [openGovTracks]);

  const governanceItems: MenuItemProps[] = useMemo(() => {
    return [...openGovItems, ...governanceLinkItems];
  }, [governanceLinkItems, openGovItems]);

  const itemsByCategory: MenuCategoryProps[] = useMemo(() => {
    let memoItems = [
      {
        label: 'home',
        path: '',
        icon: <TbSmartHome />,
      },
      {
        label: 'staking',
        items: stakingItems,
        icon: <TbPlant />,
      },
      {
        label: 'bridges',
        items: crossChainItems,
        icon: <BiTransfer />,
      },
      {
        label: 'governance',
        items: governanceItems,
        icon: <MdOutlineHowToVote />,
      },
      {
        label: 'proxy',
        path: 'proxy',
        icon: <SiEnvoyproxy />,
      },
      {
        label: 'onRamp',
        items: onRampItems,
        icon: <CiCreditCard1 />,
      },
      ...(!isAppDirDisabled
        ? [
            {
              label: 'projects',
              path: 'app-dir',
              items: appDirItems,
              icon: <BsGlobe2 />,
            },
          ]
        : []),
      {
        label: 'crowdloanRewards',
        path: 'crowdloan-rewards',
        icon: <BiCoinStack />,
      },
    ];

    // find proxy in array and remove it if disabled
    if (isProxyPageDisabled) {
      memoItems = memoItems.filter((item) => item.path !== 'proxy');
    }

    return memoItems;
  }, [
    crossChainItems,
    governanceItems,
    isAppDirDisabled,
    isProxyPageDisabled,
    stakingItems,
  ]);

  return (
    <Container px={26}>
      {itemsByCategory.map((category) => (
        <MenuCategory {...category} key={category.label}></MenuCategory>
      ))}
    </Container>
  );
};
