import {
  Box,
  Container,
  Group,
  AppShell as MantineShell,
  useMantineTheme,
} from '@mantine/core';
import { Footer } from 'components/AppShell/Footer';
import { Navbar } from 'components/AppShell/Navbar';
import { ChangeMoonChainModal } from 'components/AppShell/Navbar/ChangeMoonChainModal';
import { OpenMenuButton } from 'components/AppShell/OpenMenuButton';
import { ConnectWallet } from 'components/ConnectWallet';
import { MaintenanceBanner } from 'components/MaintenanceBanner';
import { OffsiteModal } from 'components/OffsiteLink/OffsiteModal';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isMoonChainSelectionOpenAtom } from 'recoil/isMoonChainSelectionOpen';
import { isNavbarCollapsedAtom } from 'recoil/isNavbarCollapsed';
import { useMobileNavbar } from 'recoil/mobileNavbar';
import { useInitSubstrateExtension } from 'recoil/substrateExtension';

interface Props {
  children: React.ReactNode;
}

export const AppShell = ({ children }: Props) => {
  const theme = useMantineTheme();
  const [isMoonChainModalOpen, setIsMoonChainModalOpen] = useRecoilState(
    isMoonChainSelectionOpenAtom,
  );
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();

  const isNavbarCollapsed = useRecoilValue(isNavbarCollapsedAtom);
  const expandedWidth = 258;
  const contractedWidth = 80;
  const navbarWidth = {
    sm: isNavbarCollapsed ? contractedWidth : expandedWidth,
    lg: isNavbarCollapsed ? 0 : expandedWidth,
  };

  const { isOpen } = useMobileNavbar();

  useInitSubstrateExtension();

  if (!isMounted) return null;

  return (
    <>
      <OffsiteModal />
      <ChangeMoonChainModal
        isOpen={isMoonChainModalOpen}
        setIsOpen={setIsMoonChainModalOpen}
      />
      <MantineShell
        padding={'md'}
        navbar={{
          width: navbarWidth,
          breakpoint: 'sm',
          collapsed: { mobile: !isOpen },
        }}
        footer={{ height: 29 }}
      >
        <Box pt={'lg'} pl={'lg'} hiddenFrom={'sm'}>
          <OpenMenuButton />
        </Box>
        <Navbar />
        <MantineShell.Main>
          <Container size={'xl'}>
            <Group
              justify={isMobile ? 'center' : 'right'}
              gap={'xs'}
              mb={isMobile ? theme.spacing.lg : undefined}
            >
              <ConnectWallet />
            </Group>
            <MaintenanceBanner />
            {children}
          </Container>
        </MantineShell.Main>
        <MantineShell.Footer>
          <Footer />
        </MantineShell.Footer>
      </MantineShell>
    </>
  );
};
