import { MrlToken } from 'services/mrl/tokens/Token';

// export const moonbaseAlphaWETH = MrlToken.create({
//   symbol: 'WETH',
//   decimals: 18,
//   address: '0xd27d8883E31FAA11B2613b14BE83ad8951C8783C',
//   original: 'Ethereum',
//   chain: 'Moonbeam',
//   key: 'weth',
//   name: 'WETH',
// });

// export const moonbaseAlphaUSDCeth = MrlToken.create({
//   symbol: 'USDC',
//   decimals: 6,
//   address: '0xE5dE10C4b744bac6b783fAF8d9B9fDFF14Acc3c9',
//   original: 'Ethereum',
//   chain: 'Moonbeam',
//   key: 'usdceth',
//   name: 'USDCeth',
// });

// export const moonbaseAlphaUSDT = MrlToken.create({
//   symbol: 'USDT',
//   decimals: 6,
//   address: '0x7f5Ca1bcFb38fDF4c0E0646FCbf3FA87740ff65D',
//   original: 'Ethereum',
//   chain: 'Moonbeam',
//   key: 'usdt',
//   name: 'USDT',
// });

// export const moonbaseAlphaDAI = MrlToken.create({
//   symbol: 'DAI',
//   decimals: 18,
//   address: '0xc31EC0108D8e886be58808B4C2C53f8365f1885D',
//   original: 'Ethereum',
//   chain: 'Moonbeam',
//   key: 'dai',
//   name: 'DAI',
// });

// export const moonbaseAlphaWMATIC = MrlToken.create({
//   symbol: 'WMATIC',
//   decimals: 18,
//   address: '0xD2888f015BcB76CE3d27b6024cdEFA16836d0dbb',
//   original: 'Polygon',
//   chain: 'Moonbeam',
//   key: 'wmatic',
//   name: 'WMATIC',
// });

// export const moonbaseAlphaWBNB = MrlToken.create({
//   symbol: 'WBNB',
//   decimals: 18,
//   address: '0x6097E80331B0c6aF4F74D7F2363E70Cb2Fd078A5',
//   original: 'Bsc',
//   chain: 'Moonbeam',
//   key: 'wbnb',
//   name: 'WBNB',
// });

// export const moonbaseAlphaWAVAX = MrlToken.create({
//   symbol: 'WAVAX',
//   decimals: 18,
//   address: '0x2E8afeCC19842229358f3650cc3F091908dcbaB4',
//   original: 'Avalanche',
//   chain: 'Moonbeam',
//   key: 'wavax',
//   name: 'WAVAX',
// });

// export const moonbaseAlphaUSDCavax = MrlToken.create({
//   symbol: 'USDC',
//   decimals: 6,
//   address: '0x6533CE14804D113b1F494dC56c5D60A43cb5C3b5',
//   original: 'Avalanche',
//   chain: 'Moonbeam',
//   key: 'usdcavax',
//   name: 'USDCavax',
// });

export const moonbaseAlphaWFTM = MrlToken.create({
  symbol: 'WFTM',
  decimals: 18,
  address: '0x566c1cebc6A4AFa1C122E039C4BEBe77043148Ee',
  original: 'Fantom',
  chain: 'Moonbeam',
  key: 'wftm',
  name: 'WFTM',
});

export const moonbaseAlphaAgng = MrlToken.create({
  symbol: 'AGNG',
  decimals: 18,
  address: '0xFFfFFFFF38794F8c53fC7325ea07463dc6965e20',
  original: 'Peaq',
  chain: 'Moonbeam',
  key: 'agng',
  name: 'AGNG',
});

// export const moonbaseAlphaCELO = MrlToken.create({
//   symbol: 'CELO',
//   decimals: 18,
//   address: '0x3406a9b09adf0cb36DC04c1523C4b294C6b79513',
//   original: 'Celo',
//   chain: 'Moonbeam',
//   key: 'celo',
//   name: 'CELO',
// });

export const moonbaseAlphaGLMR = MrlToken.create({
  symbol: 'GLMR',
  decimals: 18,
  isNative: true,
  wrappedKey: 'WGLMR',
  chain: 'Moonbeam',
  key: 'glmr',
  name: 'GLMR',
});

// export const moonbaseAlphaWGLMR = MrlToken.create({
//   symbol: 'WGLMR',
//   decimals: 18,
//   address: '0xD909178CC99d318e4D46e7E66a972955859670E1',
//   chain: 'Moonbeam',
//   key: 'wglmr',
//   name: 'WGLMR',
// });

// export const moonbaseAlphaSUI = MrlToken.create({
//   symbol: 'SUI',
//   decimals: 9,
//   address: '0x2ed4B5B1071A3C676664E9085C0e3826542C1b27',
//   original: 'Sui',
//   chain: 'Moonbeam',
//   key: 'sui',
//   name: 'SUI',
// });

// export const moonbaseAlphaAPT = MrlToken.create({
//   symbol: 'APT',
//   decimals: 8,
//   address: '0xCaa2A1d3BbbA0D1466571e83b4E2CbE04252593D',
//   original: 'Aptos',
//   chain: 'Moonbeam',
//   key: 'apt',
//   name: 'APT',
// });

// export const moonbaseAlphaWETHarbitrum = MrlToken.create({
//   symbol: 'WETH',
//   decimals: 18,
//   address: '0x15025b956969DD8F1d0CD69959Ad97128F8f6D69',
//   original: 'Arbitrum',
//   chain: 'Moonbeam',
//   key: 'wetharbitrum',
//   name: 'WETHarbitrum',
// });

// export const moonbaseAlphaUSDCoptimism = MrlToken.create({
//   symbol: 'USDC',
//   decimals: 6,
//   address: '0xf98E630a3DD4F21Cab7a37Bb01209cb62959169D',
//   original: 'Optimism',
//   chain: 'Moonbeam',
//   key: 'usdcoptimism',
//   name: 'USDCoptimism',
// });

// export const moonbaseAlphaUSDCbase = MrlToken.create({
//   symbol: 'USDC',
//   decimals: 6,
//   address: '0x7480641F5B00b4Fc39d6AaeC4Cd851EdEA7f31CF',
//   original: 'Base',
//   chain: 'Moonbeam',
//   key: 'usdcbase',
//   name: 'USDCbase',
// });

// export const moonbaseAlphatBTC = MrlToken.create({
//   symbol: 'tBTC',
//   decimals: 18,
//   address: '0xf82E21cE03471983Afb9c2E3789Aa13a2d7242E8',
//   original: 'Ethereum',
//   chain: 'Moonbeam',
//   key: 'tbtc',
//   name: 'tBTC',
// });

// export const moonbaseAlphaSEI = MrlToken.create({
//   symbol: 'SEI',
//   decimals: 6,
//   address: '0x1EdDe35B7e058194B457B8621285EaFA710f01ea',
//   original: 'Sei',
//   chain: 'Moonbeam',
//   key: 'sei',
//   name: 'SEI',
// });
