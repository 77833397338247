// export {
//   polygonDai, polygonWETH } from './mainnet/Polygon';

export {
  // fantomTestnetDAI,
  fantomTestnetFTM,
  // fantomTestnetUSDCeth,
  // fantomTestnetUSDT,
  // fantomTestnetWETH,
  fantomTestnetWFTM,
  fantomTestnetWGLMR,
} from './testnet/fantomTestnet';
export {
  // moonbaseAlphaAPT,
  // moonbaseAlphaCELO,
  // moonbaseAlphaDAI,
  moonbaseAlphaGLMR,
  // moonbaseAlphaSEI,
  // moonbaseAlphaSUI,
  // moonbaseAlphaUSDCavax,
  // moonbaseAlphaUSDCbase,
  // moonbaseAlphaUSDCeth,
  // moonbaseAlphaUSDCoptimism,
  // moonbaseAlphaUSDT,
  // moonbaseAlphaWAVAX,
  // moonbaseAlphaWBNB,
  // moonbaseAlphaWETH,
  // moonbaseAlphaWETHarbitrum,
  moonbaseAlphaWFTM,
  // moonbaseAlphaWGLMR,
  // moonbaseAlphaWMATIC,
  // moonbaseAlphatBTC,
} from './testnet/moonbaseAlpha';
