import { useMantineTheme } from '@mantine/core';
import { toDecimal } from '@moonbeam-network/xcm-utils';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useThemeColors } from 'hooks/useThemeColors';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useUserSettings } from 'recoil/userSettings';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

interface Props {
  labels: string[];
  rewards: bigint[];
}

export function Chart({ labels, rewards }: Props) {
  const theme = useMantineTheme();
  const { decimals } = useMoonChain();
  const { areBalancesVisible } = useUserSettings();
  const { themeColors } = useThemeColors();

  const chartOptions = useMemo(
    (): ChartOptions<'line'> => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            color: themeColors.texts,
            padding: 10,
            font: {
              family: theme.fontFamily,
            },
          },
          offset: true,
        },
        y: {
          min: 0,
          border: {
            display: false,
          },
          ticks: {
            color: themeColors.texts,
            padding: 10,
            count: 4,
            font: {
              family: theme.fontFamily,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: areBalancesVisible,
          intersect: false,
          displayColors: false,
          callbacks: {
            title: () => '',
          },
        },
      },
    }),
    [theme, areBalancesVisible, themeColors],
  );

  const data = useMemo(
    (): ChartData<'line'> => ({
      labels,
      datasets: [
        {
          data: rewards.map((x) => +toDecimal(x, decimals, 10)),
          borderColor: theme.colors[theme.primaryColor][8],
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(
              0,
              0,
              0,
              context.chart.canvas.height,
            );

            gradient.addColorStop(
              0,
              `${theme.colors[theme.primaryColor][8]}40`,
            );
            gradient.addColorStop(
              0.8,
              `${theme.colors[theme.primaryColor][8]}00`,
            );

            return gradient;
          },
          pointRadius: 0,
          borderWidth: 1,
          fill: true,
          tension: 0.2,
        },
      ],
    }),
    [theme, labels, rewards, decimals],
  );

  return <Line data={data} options={chartOptions} />;
}
