export { arbitrumETHarbitrum } from './arbitrumTokens';
export {
  ethereumDAI,
  ethereumETH,
  ethereumUSDCeth,
  ethereumUSDT,
  ethereumWBTC,
  ethereumWGLMR,
} from './ethereumTokens';
export { fantomWGLMR } from './fantomTokens';
// export {
//   // polygonDai,
//   polygonWETH,
// } from './Polygon';
