import { MrlToken } from 'services/mrl/tokens/Token';

// export const fantomTestnetWETH = MrlToken.create({
//   symbol: 'WETH',
//   decimals: 18,
//   address: '0x758FEebDDeC06f4bCcEc8F756C8efBD35d5b7124',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'weth',
//   name: 'WETH',
// });

// export const fantomTestnetUSDCeth = MrlToken.create({
//   symbol: 'USDC',
//   decimals: 6,
//   address: '0xDF7928AF5B33F7de592594958D8d6Ff8472Eb407',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'usdceth',
//   name: 'USDCeth',
// });

// export const fantomTestnetUSDT = MrlToken.create({
//   symbol: 'USDT',
//   decimals: 6,
//   address: '0x32eF19C4b3DF65a24972A489e70AdDef5E54262C',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'usdt',
//   name: 'USDT',
// });

// export const fantomTestnetDAI = MrlToken.create({
//   symbol: 'DAI',
//   decimals: 18,
//   address: '0xE4FE5DF2084f9D81595e4fcba2399020FBE7b233',
//   original: 'Ethereum',
//   chain: 'Fantom',
//   key: 'dai',
//   name: 'DAI',
// });

export const fantomTestnetFTM = MrlToken.create({
  symbol: 'FTM',
  decimals: 18,
  isNative: true,
  chain: 'Fantom',
  key: 'ftm',
  name: 'FTM',
});

export const fantomTestnetAGNG = MrlToken.create({
  symbol: 'AGNG',
  decimals: 18,
  address: '0xBb4D53C75654D28f69470546414401A2b31b586c',
  chain: 'Fantom',
  key: 'agng',
  name: 'AGNG',
});

export const fantomTestnetWFTM = MrlToken.create({
  symbol: 'WFTM',
  decimals: 18,
  address: '0xf1277d1Ed8AD466beddF92ef448A132661956621',
  chain: 'Fantom',
  key: 'wftm',
  name: 'WFTM',
});

export const fantomTestnetWGLMR = MrlToken.create({
  symbol: 'WGLMR',
  decimals: 18,
  address: '0x41E3CFDFC255A4bF3C8D3560Bc8D3D9b5080338e',
  original: 'Moonbeam',
  chain: 'Fantom',
  key: 'wglmr',
  name: 'WGLMR',
});
