import { useStakingRewardsFromSubscanAndBackend } from 'hooks/queries/staking/useStakingRewardsFromSubscanAndBackend';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { SubscanReward } from 'services/subscan';
import { formatDateShort } from 'utils/charts';

function getCutoffTimestamp(daysBack: number): number {
  const date = new Date();

  date.setDate(date.getDate() - daysBack + 1);

  return date.setHours(0, 0, 0, 0) / 1000;
}

function processRewards(
  subscanRewards: SubscanReward[],
  labels: string[],
  locale: string,
  cutoffTimestamp: number,
): { rewardsPerDay: bigint[]; latestRewards: SubscanReward[] } {
  const rewardsPerDay: bigint[] = Array(labels.length).fill(0n);

  const latestRewards: SubscanReward[] = [];
  const latestRewardsLimit = 12;

  subscanRewards
    .filter((reward, i) => {
      if (i < latestRewardsLimit) latestRewards.push(reward);

      return reward.block_timestamp > cutoffTimestamp;
    })
    .forEach((reward) => {
      const date = new Date(reward.block_timestamp * 1000);
      const index = labels.indexOf(formatDateShort(date, locale));

      rewardsPerDay[index] += BigInt(reward.amount);
    });

  return { rewardsPerDay, latestRewards };
}

interface StakingRewards {
  isLoading: boolean;
  labels: string[];
  hasFailed: boolean;
  rewards: bigint[];
  latestRewards: SubscanReward[];
}

export function useStakingRewards(
  address: string | undefined,
  daysToShow: number,
): StakingRewards {
  const { locale = 'en_US' } = useRouter();

  const labels = useMemo(() => {
    return Array(daysToShow)
      .fill(undefined)
      .map((_, i) => {
        const date = new Date();

        date.setDate(new Date().getDate() - (daysToShow - i) + 1);

        return formatDateShort(date, locale);
      });
  }, [locale, daysToShow]);

  const {
    rewards: unmappedRewards,
    isLoading,
    hasFailed,
  } = useStakingRewardsFromSubscanAndBackend({
    address,
    cutoffTimestamp: getCutoffTimestamp(daysToShow),
  });

  const { rewardsPerDay, latestRewards } = useMemo(() => {
    const { rewardsPerDay, latestRewards } = processRewards(
      unmappedRewards || [],
      labels,
      locale,
      getCutoffTimestamp(daysToShow),
    );

    return { rewardsPerDay, latestRewards };
  }, [unmappedRewards, labels, locale, daysToShow]);

  return {
    isLoading,
    labels,
    hasFailed,
    rewards: rewardsPerDay,
    latestRewards,
  };
}
