import { useFlags } from 'flagsmith/react';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { parseJson } from 'utils/json';

interface StakingAnnouncement {
  text: string;
  link: string;
}

type FlagValue = Record<string | 'global', StakingAnnouncement>;

interface StakingAnnouncements {
  isEnabled: boolean;
  announcement: StakingAnnouncement | undefined;
}

function getAnnouncement(
  moonChainKey: string,
  announcement?: string,
): StakingAnnouncement | undefined {
  if (!announcement) return undefined;

  const flagValue: FlagValue = parseJson(announcement) as FlagValue;

  return flagValue[moonChainKey] || flagValue['global'] || undefined;
}

export function useStakingAnnouncementFlag(): StakingAnnouncements {
  const { staking_announcement: flag } = useFlags(['staking_announcement']);
  const moonChainKey = useMoonChainKeyParam();

  try {
    return {
      isEnabled: flag.enabled,
      announcement: getAnnouncement(moonChainKey, flag.value?.toString()),
    };
  } catch (error) {
    console.error('Error decoding staking announcement', error);

    return {
      isEnabled: false,
      announcement: undefined,
    };
  }
}
