import {
  Anchor,
  Box,
  Card,
  Divider,
  Group,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { MoonTokenAmount } from 'components/TokenAmount';
import { useMoonBalances } from 'hooks/queries/balances/useMoonChainBalances';
import { useRouteLink } from 'hooks/router/useRouteLink';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { IoInformationCircleOutline } from 'react-icons/io5';
import classes from './Balance.module.css';
import { Chart } from './Chart';

interface Props {
  address: string | undefined;
  isStakingPage?: boolean;
}

export function Balance({ address, isStakingPage }: Props) {
  const { t } = useTranslation();
  const balances = useMoonBalances(address);
  const { isCurrentPath, fullPath } = useRouteLink('staking');

  return (
    <Card p={'xl'} className={classes.card}>
      <Title order={2} size={'h4'} mb={'lg'}>
        {t('balance')}
      </Title>
      <div className={classes.chartBox}>
        {!!balances && (
          <Chart
            free={balances.free}
            lockedStaking={balances.lockedStaking}
            lockedDemocracy={balances.lockedDemocracy}
          />
        )}
      </div>
      <Divider mt={'lg'} />
      <Text mt={'sm'} fw={'bold'}>
        {t('total')}
        <MoonTokenAmount
          span
          ml={'sm'}
          fw={'normal'}
          amount={balances?.total}
          isHidable={true}
          maxDecimals={6}
        />
      </Text>
      <Group mt={'sm'} justify={'space-between'}>
        <Text fw={'bold'}>
          {t(isStakingPage ? 'availableForStaking' : 'freeBalance')}
          <MoonTokenAmount
            span
            ml={'sm'}
            fw={'normal'}
            amount={
              isStakingPage ? balances?.availableForStaking : balances?.free
            }
            isHidable={true}
            maxDecimals={6}
          />
        </Text>
        <Tooltip
          label={t(
            isStakingPage ? 'availableForStakingTooltip' : 'freeBalanceTooltip',
          )}
          multiline
          w={300}
          withArrow
        >
          <Box>
            <IoInformationCircleOutline />
          </Box>
        </Tooltip>
      </Group>
      <Group mt={'sm'} justify={'space-between'}>
        <Text fw={'bold'}>
          {t('reservedStaking')}
          {balances?.lockedStaking === 0n &&
          balances.availableForStaking > 0n &&
          !isCurrentPath ? (
            <Anchor component={Link} href={fullPath} td={'none'}>
              <Box display={'inline-block'}>
                <ActionButton size={'compact-sm'} ml={'sm'}>
                  {t('startStaking')}
                </ActionButton>
              </Box>
            </Anchor>
          ) : (
            <MoonTokenAmount
              span
              ml={'sm'}
              fw={'normal'}
              amount={balances?.lockedStaking}
              isHidable={true}
              maxDecimals={6}
            />
          )}
        </Text>
        <Tooltip
          label={t('reservedStakingTooltip')}
          multiline
          w={300}
          withArrow
        >
          <Box>
            <IoInformationCircleOutline />
          </Box>
        </Tooltip>
      </Group>
      <Group mt={'sm'} justify={'space-between'}>
        <Text fw={'bold'}>
          {t('lockedDemocracy')}
          <MoonTokenAmount
            span
            ml={'sm'}
            fw={'normal'}
            amount={balances?.lockedDemocracy}
            isHidable={true}
            maxDecimals={6}
          />
        </Text>
        <Tooltip
          label={t('lockedDemocracyTooltip')}
          multiline
          w={300}
          withArrow
        >
          <Box>
            <IoInformationCircleOutline />
          </Box>
        </Tooltip>
      </Group>
    </Card>
  );
}
