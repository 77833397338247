import { AssetAmount } from '@moonbeam-network/xcm-types';
import { CovalentBalanceItem } from 'server/procedures/wallet/query/fetchWalletBalances';
import { ParachainToken } from 'services/mrl/chains/classes/BaseChain';
import { MrlToken } from 'services/mrl/tokens/Token';
import { Address } from 'viem';

type MrlAssetBalanceParams = {
  key: string;
  decimals: number;
  balance: bigint;
  isSpam: boolean;
  symbol: string;
  isNative: boolean;
  name: string;
  // ? thinking maybe we can add directly to the token balance which is used.
  // ? maybe add methods to check if amount less than min or more than max
  min?: number;
  max?: number;
  address?: Address;
  tokenLogoUrl?: string;
};

export class MrlAssetBalance {
  readonly key: string;
  readonly decimals: number;
  readonly balance: bigint;
  readonly isSpam: boolean;
  readonly symbol: string;
  readonly isNative: boolean;
  readonly name: string;
  readonly min?: number;
  readonly max?: number;
  readonly address?: Address;
  readonly tokenLogoUrl?: string;

  private constructor({
    key,
    decimals,
    balance,
    isSpam,
    symbol,
    isNative,
    name,
    min,
    max,
    address,
    tokenLogoUrl,
  }: MrlAssetBalanceParams) {
    this.key = key;
    this.decimals = decimals;
    this.balance = balance;
    this.isSpam = isSpam;
    this.symbol = symbol;
    this.isNative = isNative;
    this.name = name;
    this.min = min;
    this.max = max;
    this.address = address;
    this.tokenLogoUrl = tokenLogoUrl;
  }

  public static createFromMrlTokenAndBalance(
    token: MrlToken,
    balance?: CovalentBalanceItem,
  ): MrlAssetBalance {
    return new MrlAssetBalance({
      key: token.key,
      decimals: balance?.decimals ?? token.decimals,
      balance: balance?.balance ?? 0n,
      isSpam: token.isSpam,
      symbol: token.symbol,
      isNative: token.isNative,
      name: balance?.name ?? token.name,
      address: balance?.address ?? token.address,
      tokenLogoUrl: balance?.tokenLogoUrl,
    });
  }

  public static createFromParaTokenAndBalance(
    token: ParachainToken,
    balance?: AssetAmount,
  ): MrlAssetBalance {
    return new MrlAssetBalance({
      key: balance?.key ?? token.asset.key,
      decimals: balance?.decimals ?? 0,
      balance: balance?.amount ?? 0n,
      symbol: balance?.getSymbol() ?? token.asset.originSymbol,
      name: token.name ?? balance?.originSymbol ?? token.asset.originSymbol,
      address: token?.address,
      isSpam: false,
      isNative: false, // needs to verify
    });
  }

  public static createFromAnyBalance(
    balance: CovalentBalanceItem | AssetAmount,
  ): MrlAssetBalance {
    const address =
      'address' in balance ? (balance.address as Address) : undefined;
    const decimals = 'decimals' in balance ? balance.decimals : undefined;
    const amount = 'balance' in balance ? balance.balance : balance.amount;
    const name =
      'originSymbol' in balance ? balance.originSymbol : balance.name;
    const symbol =
      'getSymbol' in balance ? balance.getSymbol() : balance.symbol;

    if (!Number.isFinite(decimals)) {
      throw new Error('Decimals is missing');
    }

    return new MrlAssetBalance({
      key: balance.key,
      decimals: decimals as number,
      balance: amount ?? 0n,
      symbol,
      name,
      address,
      isSpam: false,
      isNative: false, // needs to verify
    });
  }
}
